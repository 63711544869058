<template>
  <div>
    <!-- <b-button v-b-toggle.collapse-3 class="m-1">Toggle Collapse</b-button> -->
    <div>
      <b-row class="d-flex align-items-end justify-content-end mb-1">
        <b-button
          size="sm"
          variant="outline-primary"
          :class="visible ? null : 'collapsed'"
          :aria-expanded="visible ? 'true' : 'false'"
          aria-controls="collapse-4"
          @click="visible = !visible"
        >
          {{ visible ? "Hide Filters" : "Show Filters" }}
        </b-button>
      </b-row>
      <b-collapse id="collapse-4" v-model="visible" class="mt-2">
        <b-card>
          <b-row>
            <!-- <b-col md="4">
              <b-form-group label=" Date Range" label-for="mc-revenue-date">
                <flat-pickr
                  @on-change="filterData"
                  v-model="dateRange"
                  placeholder=" Date Range"
                  class="form-control"
                  :config="{ mode: 'range' }"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="3">
              <b-form-group label="Show Report By">
                <v-select
                  @input="filterData"
                  :options="reportByData"
                  multiple
                  label="title"
                  value="value"
                  v-model="reportBy"
                  :close-on-select="false"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Movies">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :reduce="(label) => label.movie_id"
                  @input="filterData"
                  v-model="selectedMovie"
                  placeholder="Select Movies"
                  label="full_title"
                  value="movie_id"
                  :options="optionMovies"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Rights">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="rights"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="rights_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Rights"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label=" Filter By Territories">
                <v-select
                  @input="filterData"
                  v-model="territory"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="territory_list"
                  :reduce="(label) => label.lbl_id"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Territories"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Filter By  Languages">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="language"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="language_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select  Languages"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Director">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="director"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="director_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Director"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Cast">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="cast"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cast_list"
                  multiple
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Cast"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Genre">
                <v-select
                  :reduce="(label) => label.lbl_id"
                  @input="filterData"
                  v-model="genre"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genre_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Genre"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter By Countries">
                <v-select
                  @input="filterData"
                  v-model="countryData"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="country_list"
                  :reduce="(label) => label.lbl_id"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Select Countries"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Filter  Rights Available By Date Range">
                <flat-pickr
                  @on-change="filterData"
                  v-model="dateRange"
                  placeholder="Date"
                  class="form-control"
                  :config="{ mode: 'range', ...$flatPickrConfig }"
                /> </b-form-group
            ></b-col> </b-row
        ></b-card>
      </b-collapse>
    </div>
    <vue-good-table
      ref="myCustomTable"
      :group-options="{
        enabled: true,
        headerPosition: 'top',

        collapsable: true,
      }"
      :columns="columns"
      :rows="tablerows"
    >
      <template slot="table-header-row" slot-scope="props">
        <div>
          <div class="alert-body">
            <b
              ><h4>{{ props.row.title.toUpperCase() }}</h4></b
            >
          </div>
          <b-row>
            <b-col md="1">
              <b-img
                :src="BASE_URL_FILES + props.row.movie_art"
                rounded
                width="62px"
              />
            </b-col>
            <b-col md="4">
              <span style="color: grey"
                >ISAN : {{ props.row.isan_number }}<br />
                Unique ID : {{ props.row.movie_unique }}<br />
                Year : {{ props.row.year }} <br />
                Duration : {{ props.row.duration }} min <br />
                Genre : {{ props.row.movie_genere }}</span
              >
              <br />
            </b-col>
            <b-col md="3">
              <span style="color: grey">
                Original Languages: {{ props.row.original_languages }} <br />
                Available Languages: {{ props.row.available_languages }} <br />
                Available Subtitles: {{ props.row.available_subTitles }}<br />
                Country: {{ props.row.movie_country }}
              </span>
            </b-col>

            <b-col md="4">
              <span style="color: grey">
                Director : {{ props.row.director_names }} <br />
                Cast: {{ props.row.cast_names }}<br />
                Synopsis: {{ props.row.movie_synopsis }}
              </span>
            </b-col>
          </b-row>

          <b-row class="m-1">
            <h6>Rights Release Dates</h6>
            <table>
              <tr>
                <th>Rights</th>
                <th>Territories</th>
                <th>Languages</th>
                <th>Release Date</th>
                <th>Show In PDF</th>
              </tr>
              <tr
                v-for="(item, index) in props.row.movie_released_array"
                :key="index"
              >
                <td>{{ item.right_name }}</td>
                <td>{{ item.territory_name }}</td>
                <td>{{ item.language_name }}</td>
                <td>
                  {{ item.date ? moment(item.date).format("DD/MM/YYYY") : "" }}
                </td>
                <td>
                  <b-form-checkbox checked="true"></b-form-checkbox>
                </td>
              </tr>
              <tr v-if="props.row.movie_released_array.length <= 0">
                <td colspan="5" class="text-muted">Release Dates Not Added</td>
              </tr>
            </table>
          </b-row>
          <b-row class="m-1">
            <b-col
              md="6"
              class="d-flex align-items-start justify-content-start"
            >
              <b-button
                class="mr-1"
                @click="onMovieClicked(props.row, 'A')"
                variant="outline-danger"
                size="sm"
                >Show Rights Available to sell / Open Rights</b-button
              >
              <b-button
                class="mr-1"
                @click="onMovieClicked(props.row, 'S')"
                variant="outline-danger"
                size="sm"
                >Show Rights Sold</b-button
              >
            </b-col>
            <b-col md="6" class="d-flex align-items-end justify-content-end">
              <b-button
                disabled
                class="mr-1"
                variant="outline-success"
                size="sm"
                >Generate PDF</b-button
              >
              <!-- <b-button
                disabled
                class="mr-1"
                variant="outline-success"
                size="sm"
                >Generate Sold Rights PDF</b-button
              > -->
            </b-col>
          </b-row>

          <!-- <span style="colour: red" class="my-fancy-class">
     
        </span> -->
        </div>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'contract_begin'">
          <span
            v-for="(item, index) in props.row.available_dateRange_array"
            :key="index"
          >
            <span v-if="index == 0"> {{ item.free_range }}<br /> </span>
            <span v-if="index > 0">
              {{
                props.row.available_dateRange_array[
                  index - 1
                ].booked_range.split("-")[1] +
                " - " +
                item.free_range.split("-")[1]
              }}<br />
            </span>

            <span
              v-if="index == props.row.available_dateRange_array.length - 1"
            >
              {{ item.booked_range.split("-")[1] }} -
              {{
                props.row.contract_end
                  ? moment(props.row.contract_end).format("DD/MM/YYYY")
                  : ""
              }}<br />
            </span>
          </span>

          <span v-if="props.row.contract_nature == 'Sales'">
            {{
              props.row.contract_begin
                ? moment(props.row.contract_begin).format("DD/MM/YYYY")
                : ""
            }}
            -
            {{
              props.row.contract_end
                ? moment(props.row.contract_end).format("DD/MM/YYYY")
                : ""
            }}
          </span>
          <!-- <span v-if="props.row.available_dateRange_array">
            {{
              props.row.available_dateRange_array.length > 0
                ? props.row.available_dateRange_array
                    .pop()
                    .booked_range.split("-")[1]
                : ""
            }}
          </span> -->
          <!-- {{
            props.row.contract_begin
              ? moment(props.row.contract_begin).format("DD/MM/YYYY")
              : ""
          }}
          -
          {{
            props.row.contract_end
              ? moment(props.row.contract_end).format("DD/MM/YYYY")
              : ""
          }} -->
        </span>
      </template>

      <!-- <template slot="table-row" slot-scope="data">
        <span v-if="data.column.field == 'action'">
          jhjk
           <span
            v-for="(item, index) of data.row.contract_nature.split(',')"
            :key="index"
            >{{ index == 0 ? "" : item }}</span
          > 
        </span>
      </template> -->
    </vue-good-table>
  </div>
</template>

<script>
import {
  BCard,
  BFormCheckbox,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  BFormGroup,
  BCardText,
  BCollapse,
  BAlert,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

import "vue-good-table/dist/vue-good-table.css";
import Ripple from "vue-ripple-directive";

import vSelect from "vue-select";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { getAllMovie } from "@/apiServices/MovieServices";
import { GetSalesAvailableReport } from "@/apiServices/ReportsServices";
import moment from "moment";
import { downloadFromURL } from "@/utils/helpers";
import flatPickr from "vue-flatpickr-component";
import { getAllData } from "@/apiServices/MastersServices";

export default {
  components: {
    BAlert,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    flatPickr,
    BFormGroup,

    vSelect,
    ToastificationContentVue,
    VueGoodTable,
    BCardText,
    BCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      BASE_URL_FILES: process.env.VUE_APP_FILESURL,
      optionMovies: [],
      territory_list: [],

      territory: null,
      language_list: [],
      language: null,
      rights_list: [],
      rights: null,
      visible: true,
      director_list: [],
      director: null,
      cast_list: [],
      cast: null,
      reportBy: [
        {
          title: "Right",
          value: "subRight.lbl_id",
        },
        {
          title: "Territory",
          value: "contract_approval.territory_id",
        },
        {
          title: "Language",

          value: "contract_approval.language_id",
        },
      ],
      reportByData: [
        {
          title: "Right",
          value: "subRight.lbl_id",
        },
        {
          title: "Territory",
          value: "contract_approval.territory_id",
        },
        {
          title: "Language",

          value: "contract_approval.language_id",
        },
      ],

      genre_list: [],
      genre: null,
      countryData: null,
      dateRange: null,

      country_list: [],
      country: null,
      selectedMovie: null,
      moment,
      dateRange: null,
      reportData: [],
      columns: [],
      tablerows: [],
      fields: ["art", "movie_id", "title", "right_id"],
      pagination: {
        currentPage: 1,
        total: null,
        perPage: 10,
      },
    };
  },

  watch: {
    reportBy(newVal) {
      let checkIsRightFilterSelected = this.reportBy.filter((p) => {
        return p.value == "subRight.lbl_id";
      });

      if (checkIsRightFilterSelected.length) {
      } else {
        alert("Right cannot be removed");
        this.reportBy = [
          {
            title: "Right",
            value: "subRight.lbl_id",
          },
        ];
      }
    },
  },
  methods: {
    onMovieClicked(rowData, type) {
      this.$refs.myCustomTable.collapseAll();
      let data = {
        movie_id: rowData.movie_id,
        type: type,
      };

      this.getReportData(data);
    },
    filterData() {
      this.$refs.myCustomTable.collapseAll();
      this.getReportData();
    },
    async GetAllMovie() {
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionMovies = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    onPageChange() {
      this.$nextTick(() => {
        this.getReportData();
      });
    },
    async getAllTerritory() {
      const response = await getAllData({
        master: "territory",
        deleted: "0",
      });
      const response1 = await getAllData({
        master: "sub_right",
        deleted: "0",
      });
      const response2 = await getAllData({
        master: "language",
        deleted: "0",
      });
      const response3 = await getAllData({
        master: "director",
        deleted: "0",
      });
      const response4 = await getAllData({
        master: "cast",
        deleted: "0",
      });
      const response5 = await getAllData({
        master: "genre",
        deleted: "0",
      });
      const response6 = await getAllData({
        master: "country",
        deleted: "0",
      });

      if (response.status == 200) {
        this.territory_list = response.data.data.rows;
      }
      if (response1.status == 200) {
        this.rights_list = response1.data.data.rows.filter((z) => {
          return z.lbl_value1 != "" && z.lbl_value1 != null;
        });
      }
      if (response2.status == 200) {
        this.language_list = response2.data.data.rows;
      }
      if (response3.status == 200) {
        this.director_list = response3.data.data.rows;
      }
      if (response4.status == 200) {
        this.cast_list = response4.data.data.rows;
      }
      if (response5.status == 200) {
        this.genre_list = response5.data.data.rows;
      }
      if (response6.status == 200) {
        this.country_list = response6.data.data.rows;
      }
    },
    async getReportData(data) {
      // this.$refs.MyTable.collapseAll();
      // this.tablerows = [];

      this.columns = [
        {
          label: "Movie",
          field: "title",
        },
      ];

      if (data && data.movie_id) {
        this.columns.push({
          label: "Right",
          field: "right_name",
        });
      }

      let payload = {
        movie_id: data && data.movie_id ? data.movie_id : null,
        type: data && data.type ? data.type : null,
        filterMovieIds: this.selectedMovie,
        filteredRights: this.rights,
        reportBy: this.reportBy,
        filteredTerritory: this.territory,
        filteredLanguage: this.language,
        filteredDirector: this.director,
        filteredCast: this.cast,
        filteredGenre: this.genre,
        filteredCountry: this.countryData,
        filteredDateRange: this.dateRange,
      };

      const response = await GetSalesAvailableReport(payload);
      if (!response.status) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Data not found!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      if (response.data.data) {
        this.tablerows = response.data.data;
      }

      let checkTeritory = this.reportBy.filter((x) => {
        return x.value == "contract_approval.territory_id";
      });

      if (checkTeritory.length) {
        let obj = {
          label: "Territory",
          field: "territory_name",
        };

        this.columns.push(obj);
      }

      let checkLang = this.reportBy.filter((x) => {
        return x.value == "contract_approval.language_id";
      });

      if (checkLang.length) {
        let obj = {
          label: "Language",
          field: "language_name",
        };

        this.columns.push(obj);
      }

      if (data && data.movie_id) {
        this.columns.push({
          label: "Availability",
          field: "contract_begin",
        });
      }
    },
  },

  beforeMount() {
    this.getReportData();
    this.GetAllMovie();
    this.getAllTerritory();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
